<template>
  <div class="Clear-wrap">
    <van-dialog v-model:show="show" title="控件信息" show-cancel-button theme="round-button">
      <div class="tip">请参考群文件【清理后台教程】</div>
      <van-form label-width="6.6em">
        <van-field
          v-model="form.name"
          name="name"
          label="name"
          clearable
          placeholder="备注信息，与控件无关"
        />
        <van-field
          v-model="form.className"
          name="className"
          label="className"
          clearable
          placeholder="className"
        />
        <van-field
          v-model="form.clickable"
          name="clickable"
          label="clickable"
          clearable
          placeholder="clickable"
        />
        <van-field
          v-model="form.depth"
          name="depth"
          label="depth"
          clearable
          placeholder="depth"
        />
        <van-field
          v-model="form.drawingOrder"
          name="drawingOrder"
          label="drawingOrder"
          clearable
          placeholder="drawingOrder"
        />
        <van-field
          v-model="form.id"
          name="id"
          label="id"
          clearable
          placeholder="id"
        />
        <van-field
          v-model="form.indexInParent"
          name="indexInParent"
          label="indexInParent"
          clearable
          placeholder="indexInParent"
        />
        <van-field
          v-model="form.desc"
          name="desc"
          label="desc"
          clearable
          placeholder="desc"
        /><van-field
          v-model="form.text"
          name="text"
          label="text"
          clearable
          placeholder="text"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
export default {
  name: "Clear",
  props: [
    'form'
  ],
  components: {

  },
  setup(props) {
    let data = reactive({
      show: true,
    })
    return {
      ...toRefs(data)
    }
  },
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style scoped lang="less">
.Clear-wrap{
  /deep/ .van-dialog{
    .van-dialog__content{
      padding: 10px 15px;
      .van-cell{
        padding-left: 0;
        padding-right: 0;
      }
      .tip{
        font-size: 14px;
        text-align: left;
        color: #FF7F00;
        margin-bottom: 5px;
      }
    }
  }
}
</style>