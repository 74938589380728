<template>
  <div class="config-item-wrap">
    <van-row class="config-wrap" v-if="data.name === 'config'">
      <van-col :span="item.span || 12" class="config-item" v-for="(item, index) in data.iptval" :key="index">
        <van-cell center :title="item.name" v-if="item.hasOwnProperty('switch')">
          <template #right-icon>
            <van-switch v-model="item.switch" size="18" />
          </template>
        </van-cell>
      </van-col>
    </van-row>
    <van-row v-else>
      <van-col span="8">
        <van-checkbox v-model="data.checked" :disabled="data.isAttach" shape="square">{{
          data.name
        }}</van-checkbox>
        <van-popover
          class="fun-explain-popover"
          theme="dark"
          placement="bottom-start"
          v-model:show="showPopover"
          trigger="click"
          v-if="data.explain"
        > 
          <template v-if="(data.explain instanceof Array)">
            <p v-for="(item, index) in data.explain" :key="index">{{item}}</p>
          </template>
          <template v-else>
            {{data.explain}}
          </template>
          <template #reference>
            <van-icon name="question-o" />
          </template>
        </van-popover>
      </van-col>
      <!-- 一起来养猪配置 -->
      <van-col span="16" v-if="data.name === '未婚结婚'">
        <van-cell center title="同时分解已婚">
          <template #right-icon>
            <van-switch v-model="data.switch" size="18" />
          </template>
        </van-cell>
      </van-col>
      <van-col span="16" v-else-if="data.name === '已婚分解'">
        <van-cell center>
          <template #value>
            <van-button type="primary" size="mini" @click="showDialog = true"
            >{{data.textareaLabel}}</van-button
          >
          </template>
        </van-cell>
      </van-col>
      
      <template v-else-if="cur === 'hysj' && data.name === '抢单'">
        <van-col span="16">
          <van-cell title="最低价格：" center style="border-right: 5px solid #fff">
            <template #value>
            <van-field v-model="data.iptval" clearable placeholder="最低价格"/>
            </template>
          </van-cell>
        </van-col>
      </template>
      <template v-else-if="data.name === '出售表情'">
        <van-col span="16">
          <van-cell center>
            <template #value>
             <van-button
                type="primary"
                size="mini"
                @click="showMultiPopup = true"
                >特殊表情</van-button
              >
            </template>
          </van-cell>
        </van-col>
      </template>
      <template v-else-if="data.name.includes('宝石转赠') && cur === 'sszx'">
        <van-col :span="8">
          <van-cell center title-class="id-wrap" style="border-right: 5px solid #fff">
            <template #title>ID：</template>
            <template #value>
              <van-field v-model="data.iptval" clearable placeholder="接收人ID"/>
            </template>
          </van-cell>
        </van-col>
        <van-col span="8">
          <van-cell center title-class="reserve-coin">
            <template #title>密码：</template>
            <template #value>
              <van-field v-model="data.input" clearable placeholder="密码"/>
            </template>
          </van-cell>
        </van-col>
      </template>
      <template v-else-if="data.name.includes('赠送') || data.name === '转宝石'">
        <van-col :span="data.name === '赠送金币' ? 8 : 16">
          <van-cell center title-class="id-wrap" style="border-right: 5px solid #fff">
            <template #title>ID：</template>
            <template #value>
              <van-field v-model="data.iptval" clearable placeholder="接收人ID"/>
            </template>
          </van-cell>
        </van-col>
        <van-col span="8" v-if="data.name === '赠送金币'">
          <van-cell center title-class="reserve-coin">
            <template #title>保留：</template>
            <template #value>
              <van-field v-model="data.input" clearable placeholder="个数"/>
            </template>
          </van-cell>
        </van-col>
      </template>
      <template v-else-if="data.name === '表情专刷'">
        <van-col span="7">
          <van-cell center :title="data.title">
            <template #right-icon>
              <van-switch v-model="data.switch" size="18" />
            </template>
          </van-cell>
        </van-col>
        <van-col span="2"></van-col>
        <van-col span="7">
          <van-cell center>
            <template #value>
             <van-button
                type="primary"
                size="mini"
                @click="showMultiPopup = true"
                >选择表情</van-button
              >
            </template>
          </van-cell>
        </van-col>
      </template>
      <van-col span="16" v-else-if="data.hasOwnProperty('switch')">
        <van-cell center :title="data.title">
          <template #right-icon>
            <van-switch v-model="data.switch" size="18" />
          </template>
        </van-cell>
      </van-col>
      <van-col span="16" v-else-if="data.name === '买游侠兔'">
        <van-cell center>
          <template #title>购买数量：</template>
          <template #value>
            <van-field v-model="data.iptval" clearable placeholder="购买数量"/>
          </template>
        </van-cell>
      </van-col>

      <template v-else-if="data.local && data.local.options">
        <van-col :span="['选择食物', '选择水'].includes(data.name) ? 8 : 16">
          <van-cell>
            <template #title>
              <span>{{data.local.title}}：</span>
              <span style="color: #ff4040" @click="showConfigPicker = true">{{data.iptval}}</span>
            </template>
          </van-cell>
        </van-col>
        <van-col v-if="['选择食物', '选择水'].includes(data.name)" span="8">
          <van-cell>
            <template #title>
              <span>数量：</span>
            </template>
            <template #value>
              <van-field v-model="data.input" clearable placeholder="数量"/>
            </template>
          </van-cell>
        </van-col>
      </template>

      
    </van-row>
    
  </div>

  <div>
    <van-dialog class="textarea-wrap" v-model:show="showDialog" :before-close="beforeClose" :title="popupData.title" teleport="body">
      <van-row>
        <van-col :span="spanNum" v-for="(item, index) in data.textarea" :key="item">
          <van-tag type="success" size="large" round closeable @close="removeId(index)">{{
            item
          }}</van-tag>
        </van-col>
      </van-row>
      <van-field
        v-model="input"
        center
        clearable
        label-width="60"
        :label="popupData.label"
        :placeholder="popupData.placeholder"
        type="number"
        maxlength="8"
      >
        <template #button>
          <van-button size="small" type="primary" @click="add()">确定添加</van-button>
        </template>
      </van-field>
    </van-dialog>

    <my-picker
      :title="`选择${data.local ? data.local.title : ''}`"
      v-model:show="showConfigPicker"
      :columns="configColumns"
      @confirm="configPickerConfirm"
    >
    </my-picker>


    <van-popup v-model:show="showMultiPopup" 
      class='multi-popup-wrap'
      :style="{ height: '30%' }"
      closeable
      teleport="body" round position="bottom">
      <div class="title" v-if="data.local.title">请勾选{{data.local.title}}：</div>
      <van-checkbox-group v-model="data.iptval">
        <van-checkbox :name="item.value" shape="square" v-for="(item, index) in multiOptions" :key="index">{{item.label}}</van-checkbox>
      </van-checkbox-group>
    </van-popup>



  </div>


  
</template>

<script>
export default {
  name: "config-item",
  props: ["data", "cur"],
  components: {},
  data() {
    return {
      showDialog: false,
      input: "",
      popupData: {},
      spanNum: 8,
      showPopover: false,
      actions: [],
      showConfigPicker: false,
      configColumns: [],
      showMultiPopup: false,
      multiOptions: []
    };                                                                                                                                                                                                                                   
  },
  computed: {
  },
  watch: {
    data: {
      handler(val){
        let list = val?.local?.options || []
        if(list.length){
          this.configColumns = list
        }
        let multiOptions = val?.local?.multiOptions || []
        if(multiOptions.length){
          this.multiOptions = multiOptions
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.handlePopupData()
  },
  mounted() {},
  methods: {
    configPickerConfirm(obj){
      this.data.iptval = obj.value
    },
    handlePopupData(){
      let obj = {}
      if(this.data.hasOwnProperty('textarea')){
        switch (this.cur) {
          case 'pig':
            obj = {
              title: '已婚猪分解白名单',
              placeholder: '请输入猪猪ID',
              label: '猪猪ID',
            }
            this.spanNum = 8
            break;
        }
      }else{
        obj = {}
      }
      this.popupData = obj
    },
    removeId(index) {
      this.data.textarea.splice(index, 1);
    },
    beforeClose(){
      if(this.input){
        this.$toast('你还有未完成的编辑项')
        return false
      }else{
        return true
      }
    },
    add(){
      if(!this.input){
        return false
      }
      if(this.cur === 'pig'){
        if(!/^\d{8}$/.test(this.input)){
          this.$toast('ID格式有误')
          return false
        }
      }else{
        
      }
      
      this.data.textarea.push(this.input)
      this.input = null
      return true
    },
    handleBlur(){
      if(this.data.sleep < 0){
        this.data.sleep = 0
      }
      if(this.data.sleep < 500){
        this.$toast('建议休息时间不低于500毫秒')
      }
    }
  },
};
</script>

<style scoped lang="less">
.config-item-wrap {
  &:not(:last-child){
    margin-bottom: 8px;
  }
  /deep/ .van-row {
    height: 28px;
    display: flex;
    align-items: center;
    
    .van-col {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  /deep/ .van-cell {
    background: #efefef;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2px;
    .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .van-field__label {
      min-width: 50px;
      margin-right: 5px;
      
    }
    .van-cell__title{
      &.id-wrap{
        min-width: 30px;
        width: 30px;
        flex: none;
        text-align: center;
      }
      &.reserve-coin{
        width: 45px;
        flex: none;
        text-align: center;
      }
    }
  }
  
}
</style>
<style lang="less">
  .textarea-wrap{
    .van-dialog__header{
      padding-top: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #efefef;
    }
    .van-dialog__content{
      padding: 0 10px;
      padding-top: 10px;
      .van-row{
        display: block;
        height: 160px !important;
        overflow-y: auto;
        .van-col{
          height: 30px;
        }
      }
    }
    .van-cell{
      height: 40px;
      background: #efefef;
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  .multi-popup-wrap{
    font-size: 16px;
    padding: 15px;
    .title{
      font-weight: 500;
      margin-bottom: 10px;
    }
    .van-checkbox-group{
      display: grid;
      grid-template-columns: repeat(3, calc((100% - 40px) / 3));
      column-gap: 15px;
      row-gap: 15px;
    }
  }
  
</style>