import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from './env/axios'
import moment from 'moment'
import Decimal from 'decimal'
import lodash from 'lodash'

import './env/rem'
import store from './env/store'
import '@/assets/css/reset.css'

import Vant from 'vant'
import 'vant/lib/index.css'

import myConfig from "./env/config";


router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


const app = createApp(App)
  .use(router)
  .use(store)
  .use(Vant)

  // 全局引用的组件
import ConfigItem from '@/components/ConfigItem'
app.component('config-item', ConfigItem)
import MyPicker from '@/components/Picker'
app.component('my-picker', MyPicker)
import Clear from '@/components/Clear'
app.component('clear', Clear)


import LineChart from '@/components/LineChart'
app.component('line-chart', LineChart)
import BarChart from '@/components/BarChart'
app.component('bar-chart', BarChart)



app.config.globalProperties.axios = axios
app.config.globalProperties.mm = moment
app.config.globalProperties.Decimal = Decimal
app.config.globalProperties.$_ = lodash



class Calculate extends Decimal {
  constructor(value, isNull) {
    super(value)
    this.isNull = isNull
  }
  end(precision = 2) {
    return this.isNull ? null : lodash.round(+this, precision)
  }
  add(value) {    // isNaN([]): false
    if (value === '' || value === null || isNaN(value) || value instanceof Array || this.isNull) {
      this.isNull = true
      return this
    } else {
      return new Calculate(super.add(value))
    }
  }
  sub(value) {
    if (value === '' || value === null || isNaN(value) || value instanceof Array || this.isNull) {
      this.isNull = true
      return this
    } else {
      return new Calculate(super.sub(value))
    }
  }
  mul(value) {
    if (value === '' || value === null || isNaN(value) || value instanceof Array || this.isNull) {
      this.isNull = true
      return this
    } else {
      return new Calculate(super.mul(value))
    }
  }
  div(value) {
    if (+value === 0 || value === '' || value === null || isNaN(value) || value instanceof Array || this.isNull) {
      this.isNull = true
      return this
    } else {
      return new Calculate(super.div(value))
    }
  }
}
function calc(value) {
  if (value === '' || value === null || isNaN(value)) {
    return new Calculate(0, true) // 若初始值也为无效值，则进行初始值无效标记，并返回一个有效的Decimal实例以进行链式调用
  }
  return new Calculate(value)
}
app.config.globalProperties.$calc = calc

app.config.globalProperties.from = myConfig.from 





// 此方式是直接混入到实例自身当中，以上方式类似vue2直接挂载到Vue的原型对象上
// app.mixin({  
//   data() {
//     return {
//       axios,
//       mm,
//     }
//   }
// })


app.mount('#app')
