<template>
  <router-view/>
</template>  

<script>

export default {
  created() {
    if (sessionStorage.getItem("store") ) {   //页面加载前读取sessionStorage里的状态信息
　　   this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    } 
    window.addEventListener("beforeunload",()=>{   //在页面刷新前将vuex里的信息保存到sessionStorage里
　　   sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })
    if (this.from === 'bb') {
      document.title = '笨笨助手'
    } else {
      document.title = '笨笨合集'
    }
    document.addEventListener("visibilitychange", () => { 
      if(document.hidden) {
      } else {
        let reloadTime = localStorage.getItem('rt')
        if (reloadTime) {
          if (Date.now() - reloadTime * 1 > 1800000) {// 超过半小时刷新
            localStorage.setItem('rt', Date.now())
            location.reload()
          }
        }
      }
    });
  },
  methods: {
    
  }
};
</script>
<style lang="less">
#app {
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f7f8fa;
  min-height: 100vh;
  .tip-wrap {
    text-align: left;
    font-size: 14px;
    padding: 5px 15px;
    li {
      line-height: 22px;
      margin-bottom: 5px;
      &:first-child:not(.normal) {
        color: #FF7F00;
        font-weight: bold;
        font-size: 16px;
      }
      .emphase{
        color: #FF7F00;
      }
    }
  }
  .my-btn{
    height: 32px;
  }
}

.fun-explain-popover{
  .van-popover__content{
    padding: 10px;
    width: 50vw;
    font-size: 12px;
    text-align: left;
  }
}
</style>
