<template>
  <div class="BarChart-wrap" ref="chartEl">
    
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: "BarChart",
  props: {
    data: {
      type: Array,
      default: [],
    },
    rooms: {
      type: Array,
      default: [],
    }
  },
  components: {

  },
  data() {
    return {
      myChart: null,
    };
  },
  computed: {

  },
  watch: {
    data: {
      handler(){
        this.initChart()
      },
      deep: true
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    initChart(){
      if (!this.myChart) {
        this.myChart = echarts.init(this.$refs.chartEl)
      }
      let option = {
        title: {
          text: this.room,
          left: 'center',
          top: 'center',
          textStyle: {
            opacity: 0.2
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let markEl = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>`
            return `
            <div style="text-align: left">
              <div>${params[0].name}期-${this.room}</div>
              <div>
                ${markEl}
                <span>${params[0].value}</span>
              </div>
            </div>
            `
          }
        },
        grid: {
          top: '12%',
          bottom: '10%',
          right: '5%',
          left: '5%',
        },
        xAxis: {
          type: 'category',
          data: this.rooms,
          axisLabel: {
          },
          axisTick: {
            show: false,
          }
        },
        yAxis: {
          type: 'value',
          min: 'dataMin',
          splitLine: {
            show: false,
          }
        },
        series: [
          {
            data: this.data,
            type: 'bar',
            label: {
              show: true,
              position: 'top',
            },
            markLine: {
              data: [{ type: 'average', name: 'Avg' }]
            },
            barWidth: 25,
          }
        ]
      };
      this.myChart.setOption(option)
    }
  }
};
</script>

<style scoped lang="less">
.BarChart-wrap{
  width: 100%;
  height: 100%;
}
</style>