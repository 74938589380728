<template>
  <div class="Picker-wrap">
    <van-popup v-model:show="comShow" 
    teleport="body"
    v-bind="$attrs" round position="bottom">
      <van-picker
        @cancel="onCancel"
        @confirm="onConfirm"
        @change="onChange"
        v-bind="$attrs"
      />
    </van-popup>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue'
export default {
  name: "Picker",
  props: [
    'show'
  ],
  components: {

  },
  setup(props) {
    const data = reactive({
      // value: null
      comShow: false
    })
    return {
      ...toRefs(data)
    }
  },
  computed: {

  },
  watch: {
    show(flag) {
      this.comShow = flag
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    onConfirm(){
      this.$emit('update:show', false)
    },
    onCancel(){
      this.$emit('update:show', false)
    },
    onChange(val){
      this.value = val
    },
  }
};
</script>

<style scoped lang="less">
.Picker-wrap{

}
</style>