import { createStore } from 'vuex'
const store = createStore({
  state() {
    return {
      userInfo: {},
      loading: false,
      inviteInfo: {},
      allScriptList: [],
    }
  },
  mutations: {
    USERINFO(state, obj) {
      // console.log('store:----->', obj);
      state.userInfo = obj
    },
    LOADING(state, flag) {
      state.loading = flag
    },
    INVITEINFO(state, obj) {
      state.inviteInfo = obj
    },
    ALLSCRIPTLIST(state, arr) {
      state.allScriptList = arr
    },
    
  }
  
})

export default store