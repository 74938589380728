import axios from 'axios'
const qs = require('qs')
import { Toast } from 'vant'
import myConfig from "./config";

let baseURL = process.env.NODE_ENV === 'development' ? '/' : 'http://www.benben.icover.fun/api/'
baseURL = 'http://www.benben.icover.fun/api/'
baseURL += myConfig.from
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000
});





// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()
/**
 * 添加请求
 * @param {Object} config 
 */
const addPending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data)
  ].join('&')
  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
      pending.set(url, cancel)
    }
  })
}
/**
 * 移除请求
 * @param {Object} config 
 */
const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
    qs.stringify(config.data)
  ].join('&')
  if (pending.has(url)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(url)
    cancel(url)
    pending.delete(url)
  }
}
/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}














// 路由变化前取消当前页面的所有请求
// router.beforeEach((to, from, next) => {
//   clearPending()
//   // ...
//   next()
// })



instance.interceptors.request.use(config => {
  removePending(config) // 在请求开始前，对之前的请求做检查取消操作
  addPending(config) // 将当前请求添加到 pending 中
  // 若指定了来源，则替换from
  if (config.url.includes('bb/')) {
    config.baseURL = config.baseURL.replace('reel', '')
    config.baseURL = config.baseURL.replace('bb', '')
  }else if (config.url.includes('reel/')) {
    config.baseURL = config.baseURL.replace('bb', '')
    config.baseURL = config.baseURL.replace('reel', '')
  }
  return config
}, err => {
  return err
});


instance.interceptors.response.use(res => {
  removePending(res) // 在请求结束后，移除本次请求
  if (res?.data?.code === 0) {
    return Promise.resolve(res.data || {})
  } else {
    Toast(res?.data?.msg || '请求出错')    
    return Promise.reject(res.data || {})
  }
}, err => {
  return err
});



export default instance