let baseSize = 37.5; // 设计稿中1rem的大小。
function setRem() {
  let w = document.documentElement.clientWidth
  // 实际设备页面宽度和设计稿的比值
  let scale = document.documentElement.clientWidth / 375;
  if (w > 1240) {
    // 实际设备页面宽度和设计稿的比值
    scale = document.documentElement.clientWidth / 1920;
  }
  // 计算实际的rem值并赋予给html的font-size
  document.documentElement.style.fontSize = (baseSize * scale) + 'px';
}
document.documentElement.style.fontSize = baseSize + 'px'
setRem();
window.addEventListener('resize', () => {
  setRem();
});

