import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/views/Index'),
    redirect: '/config',
    children: [
      {
        path: 'config',
        component: () => import('@/views/Config')
      },
      {
        path: 'my',
        component: () => import('@/views/My')
      },
      {
        path: 'invite',
        component: () => import('@/views/Invite')
      },
    ]
  },
  {
    path: '/login/:type',   
    component: () => import('@/views/Login'),
  },
  {
    path: '/manage/:account',   
    component: () => import('@/views/AddTime'),
  },
  {
    path: '/monitor',   
    component: () => import('@/views/Monitor'),
  },
  


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
